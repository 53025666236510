import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import { useStore } from "../../app/stores/store";
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Suchmaschinenoptimierung in der Praxis</title>      
                <meta name="description" content="Portal zur Suchmaschinenoptimierung aus der Praxis für die Praxis" />
                <meta name="keywords" content="seo, suchmaschinenoptimierung, serps, webseitenoptimierung, google" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Suchmaschinenoptimierung in der Praxis</h1>
            <AdvertOwnBlock
                title='Suchmaschinenoptimierung für mehr Erfolg Ihrer Webseiten'
                content='<p>Die Konkurrenz ist groß im Internet und Suchmaschinen liefern nur eine TOP 10 Ergebnisseite zu einem Suchbegriff aus - alle Positionen danach sind mittlerweile nahezu bedeutungslos geworden.</p>Erfahren Sie hier, wie auch Ihre Webseite unter TOP Positionen in den Suchmaschinen gefunden werden kann.'
                image='/banners/suchmaschinenoptimierung-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Suchmaschinenoptimierung', linkTarget: '/wellness' },
                    // { linkText: 'Keywordanalyse', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                
                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/71q3vNyRSyL.jpg'
                    text='Grundlagen, strategische Planung, Erfolgsmessung, Web Analytics und Controlling, Google Search Console'
                    link='https://www.amazon.de/dp/3836276747/ref=as_li_ss_tl?crid=13K4TYTC3RJTC&dchild=1&keywords=suchmaschinenoptimierung&qid=1624096000&sprefix=suchmaschinen,aps,172&sr=8-2&linkCode=ll1&tag=kayomo-21&linkId=d7b1db5a88bc66258584a5b1543f7c38&language=de_DE'
                    title='Suchmaschinen-Optimierung Praxiswissen'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}