import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NavBar () {

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" >
            <Navbar.Brand as={Link} to="/" style={{color: 'orange'}}><FontAwesomeIcon icon={faChartLine} style={{marginRight: '0.3em'}}/>{process.env.REACT_APP_PORTAL_NAME}</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link eventKey="2" as={Link} to='/artikel'>Artikel</Nav.Link>
                {/* <Nav.Link as={Link} to='/wellness'>Wellness</Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}